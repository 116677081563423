import React, { useState } from "react";
import Layout from "../../gatsby-theme-hypersite/layout";
import {
  LocationHero,
  LawsuitsChartSection,
  LawsuitsMapSection,
  DebtCollectorsSection,
  DemographicChartSection,
  TableSection,
  ShareBlock,
} from "../sections";
import {
  getTopCollectorsData,
  getLawsuitChartData,
  getLocationHeroData,
  getDemographicChartData,
  getDateRange,
} from "../utils";
import GetTheData from "../sections/get-the-data";
import AdditionalResourcesBlock from "../../components/sections/additional-resources";

export default function SubpageLayout({
  meta,
  image,
  type,
  data,
  countyChoroplethGeojson,
  countyBubbleGeojson,
  tractChoroplethGeojson,
  tractBubbleGeojson,
  bbox,
  content,
  ...props
}) {
  const region = data.region;
  const hasMap = type !== "tract";
  const hasTable = type !== "tract";
  const hasDemographicChart = false;
  // type === "county" || (region === "zips" && data.zips.length > 0);
  const [activeLocation, setActiveLocation] = useState(null);
  const [mappedGeography, updateMappedGeography] = useState(region);
  const setMappedGeography = (newGeography) => {
    if (newGeography === mappedGeography) return;
    // fixes #157 - if you switch map view modes, clear tooltip
    setActiveLocation(null);
    updateMappedGeography(newGeography);
  };
  const [autoSwitchOnZoom, setAutoSwitchOnZoom] = useState(
    region === "counties"
  );
  const [selected, setSelected] = useState(null);
  const [locked, setLocked] = useState(false);

  const jumpToSection = (section) => {
    const yOffset = -128;
    const element = document.getElementById(section);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const onJumpToMap = ({ geoid }) => {
    console.log({ geoid });
    if (selected?.info) selected.info.properties.selected = false;
    setSelected(null);
    setAutoSwitchOnZoom(false);
    jumpToSection("map");

    const clickedGeoType =
      geoid.length > 7
        ? "tracts"
        : geoid.length === 7
        ? "zips"
        : geoid.length === 5
        ? "counties"
        : null;

    if (clickedGeoType && clickedGeoType !== mappedGeography) {
      // fixes #157 - ensure map set to appropriate geo before Jumping to it
      setMappedGeography(clickedGeoType);
      setTimeout(() => setActiveLocation(geoid), 0);
    } else {
      setActiveLocation(geoid);
    }
  };

  const dateRange = getDateRange([data]);

  return (
    <Layout meta={meta} {...props}>
      <LocationHero
        image={image}
        content={content[type].hero}
        data={getLocationHeroData(data)}
      />
      <DebtCollectorsSection
        dateRange={dateRange}
        content={content[type].collectors}
        data={getTopCollectorsData(data)}
        stateName={data.state}
      />
      <LawsuitsChartSection
        content={content[type].lawsuits}
        data={getLawsuitChartData(data)}
      />
      {hasMap && (
        <LawsuitsMapSection
          type={type}
          dateRange={dateRange}
          setActiveLocation={setActiveLocation}
          activeLocation={activeLocation}
          mappedGeography={mappedGeography}
          setMappedGeography={setMappedGeography}
          autoSwitchOnZoom={autoSwitchOnZoom}
          setAutoSwitchOnZoom={setAutoSwitchOnZoom}
          locked={locked}
          setLocked={setLocked}
          selected={selected}
          setSelected={setSelected}
          content={content[type].map}
          data={data}
          countyChoroplethGeojson={countyChoroplethGeojson}
          countyBubbleGeojson={countyBubbleGeojson}
          tractChoroplethGeojson={tractChoroplethGeojson}
          tractBubbleGeojson={tractBubbleGeojson}
          region={region}
        />
      )}
      {hasTable && (
        <TableSection
          dateRange={dateRange}
          view={region}
          content={{ ...content[type].table, ...content.table }}
          data={[data]}
          onJumpToMap={(selected) => onJumpToMap(selected)}
          tractChoroplethGeojson={tractChoroplethGeojson}
          bbox={bbox}
        />
      )}
      {hasDemographicChart && (
        <DemographicChartSection
          content={content[type].demographics}
          data={getDemographicChartData(data, region)}
        />
      )}
      <AdditionalResourcesBlock
        left={<GetTheData data={data} type={type} />}
        right={<ShareBlock />}
      />
    </Layout>
  );
}
