import React from "react";
import { graphql } from "gatsby";
import getBbox from "@turf/bbox";
import SubpageLayout from "../subpage";

export default function TrackerCountyLayout({
  children,
  pageContext,
  ...props
}) {
  const data = props.data.allCounties.nodes[0];
  const tractChoroplethGeojson = props.data.allGeojsonJson.nodes.find(
    (geojson) =>
      geojson.features.some((x) => x.geometry?.type === "MultiPolygon")
  );
  const tractBubbleGeojson = props.data.allGeojsonJson.nodes.find((geojson) =>
    geojson.features.some((x) => x.geometry?.type === "Point")
  );
  const content = props.data.allLawsuitTrackerJson.nodes[0];
  const meta = pageContext.frontmatter.meta;
  const image = props.data.allFile.nodes[0];

  if (data.geoid === "48453") {
    // filter travis county zips only)
    tractChoroplethGeojson.features = tractChoroplethGeojson.features.filter(
      (x) => travisCountyZips.includes(x.properties.GEOID.slice(2))
    );

    tractBubbleGeojson.features = tractBubbleGeojson.features.filter((x) =>
      travisCountyZips.includes(x.properties.GEOID.slice(2))
    );
  }

  if (data.geoid === "48157") {
    // filter fort bend county zips only)
    tractChoroplethGeojson.features = tractChoroplethGeojson.features.filter(
      (x) => fortBendCountyZips.includes(x.properties.GEOID.slice(2))
    );

    tractBubbleGeojson.features = tractBubbleGeojson.features.filter((x) =>
      fortBendCountyZips.includes(x.properties.GEOID.slice(2))
    );
  }

  const bbox = getBbox({
    type: "FeatureCollection",
    features: tractChoroplethGeojson.features,
  });

  return (
    <SubpageLayout
      type="county"
      {...{
        meta,
        data,
        tractBubbleGeojson,
        tractChoroplethGeojson,
        countyBubbleGeojson: tractBubbleGeojson,
        countyChoroplethGeojson: tractChoroplethGeojson,
        bbox,
        image,
        content,
      }}
    />
  );
}

export const query = graphql`
  query ($geoid: String!, $state: String!, $region: String!) {
    allFile(filter: { name: { eq: "location-hero" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 350)
        }
      }
    }
    allLawsuitTrackerJson {
      nodes {
        county {
          hero {
            STATS {
              id
              description
            }
            SMALL_CLAIMS_NOTE
          }
          collectors {
            TITLE
            DESCRIPTION
            FOOTNOTE
          }
          lawsuits {
            TITLE
            DESCRIPTION
            PANDEMIC_COMPARISON
            FOOTNOTE
          }
          map {
            TITLE
            DESCRIPTION
            LABEL
            FOOTNOTE
          }
          table {
            TITLE
            DESCRIPTION
            FOOTNOTE
          }
          demographics {
            TITLE
            DESCRIPTION
            BREAKDOWN_TITLE
            BREAKDOWN_LABEL
            COUNT_CHART_TITLE
            COUNT_CHART_TOOLTIP
            PROPORTION_CHART_TITLE
            PROPORTION_CHART_TOOLTIP
            FOOTNOTE
          }
        }
        table {
          LAST_UPDATED
          TOP_LIMIT
          NORTH_DAKOTA_NOTE
          TEXAS_NOTE
          OHIO_NOTE
          PENNSYLVANIA_NOTE
          COUNTIES_NOTE
          TRACTS_NOTE
          ZIPS_NOTE
          STATES_NOTE
          NO_RESULTS
          DEFAULT_JUDGEMENTS_HINT
          REPORT_LINK
        }
      }
    }
    allGeojsonJson(filter: { name: { eq: $state }, region: { eq: $region } }) {
      nodes {
        features {
          properties {
            GEOID
            NAME
          }
          geometry {
            type
            coordinates
          }
          type
        }
      }
    }
    allCounties(filter: { geoid: { eq: $geoid } }) {
      nodes {
        geoid
        name
        region
        state
        lawsuits
        completed_lawsuits
        default_judgement
        # NOTE: temporarily ignore new fields
        # post_judgement
        # engagement
        # answers_filed
        no_rep_percent
        top_collectors {
          amount
          collector
          lawsuits
        }
        collector_total
        lawsuit_history {
          lawsuits
          month
        }
        proportions {
          group
          tractPercent
          lawsuitPercent
        }
        tracts {
          geoid
          default_judgement
          # NOTE: temporarily ignore new fields
          # post_judgement
          # engagement
          # answers_filed
          lawsuits
          lawsuits_per_100
          name
          no_rep_percent
          lawsuit_history {
            lawsuits
            month
          }
          percent_asian
          percent_black
          percent_latinx
          percent_other
          percent_white
          median_hhi
          pct_renter
          pct_owner
          household_size_owner
          household_size_renter
          unemployment_rate
          pct_poverty
          pct_noncitizen
          pct_uninsured
          majority
        }
        zips {
          default_judgement
          # NOTE: temporarily ignore new fields
          # post_judgement
          # engagement
          # answers_filed
          geoid
          lawsuits
          lawsuits_per_100
          name
          no_rep_percent
          lawsuit_history {
            lawsuits
            month
          }
          percent_asian
          percent_black
          percent_latinx
          percent_other
          percent_white
          median_hhi
          pct_renter
          pct_owner
          household_size_owner
          household_size_renter
          unemployment_rate
          pct_poverty
          pct_noncitizen
          pct_uninsured
          majority
        }
      }
    }
  }
`;

const fortBendCountyZips = [
  "77494",
  "77479",
  "77459",
  "77083",
  "77407",
  "77450",
  "77406",
  "77469",
  "77498",
  "77471",
  "77489",
  "77477",
  "77053",
  "77545",
  "77478",
  "77441",
  "77461",
  "77485",
  "77435",
  "77420",
  "77430",
  "77417",
  "77444",
  "77451",
  "77476",
  "77481",
  "77464",
  "77487",
  "77496",
  "77497",
];
const travisCountyZips = [
  "78660",
  "78613",
  "78641",
  "78745",
  "78664",
  "78753",
  "78758",
  "78704",
  "78748",
  "78744",
  "78741",
  "78759",
  "78610",
  "78653",
  "78723",
  "78749",
  "78750",
  "78617",
  "78757",
  "78746",
  "78727",
  "78737",
  "78724",
  "78728",
  "78754",
  "78731",
  "78702",
  "78705",
  "78738",
  "78621",
  "78703",
  "78734",
  "78747",
  "78739",
  "78732",
  "78752",
  "78735",
  "78654",
  "78751",
  "78726",
  "78645",
  "78669",
  "78733",
  "78701",
  "78736",
  "78721",
  "78756",
  "78730",
  "78652",
  "78712",
  "78725",
  "78722",
  "78719",
  "78615",
  "78742",
  "78788",
  "78786",
  "78769",
  "78781",
  "78780",
  "78785",
  "78789",
  "78755",
  "78761",
  "78764",
  "78763",
  "78766",
  "78765",
  "78768",
  "78767",
  "78772",
  "78774",
  "78773",
  "78779",
  "78778",
  "78783",
  "78799",
  "73301",
  "73344",
  "78691",
  "78708",
  "78710",
  "78709",
  "78711",
  "78714",
  "78713",
  "78716",
  "78715",
  "78718",
  "78720",
  "78760",
  "78762",
];
